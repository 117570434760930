<template>
    <div class="p-5 mt-5">
        <!-- <p class="fw-bold">1SOP24010387</p> -->
            <form class="row g-3 mb-3" id="frmPengajuan" @submit.prevent="saveData" autocomplete="off">
                <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">Masukan SO</label>
                <input type="text" class="form-control" id="inputPassword2" placeholder="Masukan SO" v-model="rec.ta_so" required>
                </div>
                <div class="col-auto">
                <label for="enty" class="visually-hidden">Masukan SO</label>
                <select class="form-select" id="enty" placeholder="entity" v-model="rec.ta_enty" disabled required>
                    <option value="01" selected>SBY</option>
                    <option value="02">SMG</option>
                </select>
                </div>
                <div class="col-auto">
                <button form="frmPengajuan" class="btn btn-success rounded" type="submit" :disabled="isFormInvalid"><i class="fa fa-save"></i> Pengajuan</button>
                </div>
            </form>
            <div class="row">
                <div v-if="showDiv" class="col-6">
                    <button @click="copyToClipboard" class="btn btn-primary">Copy</button>
                    <div v-for="(dt, index) in recc" :key="index">
                    <p class="h5 fw-bold">Customer : {{dt.custname}}</p>
                    <p class="h5 fw-bold">Credit Limit : {{dt.creditlimit | toRp}}</p>
                    <p class="h5 fw-bold">Sisa Credit : {{dt.creditlimit - totalNetAmt | toRp}}</p>
                </div>
                <p class="h5 fw-bold">top : {{this.top}}</p>
                    <p class="h5 fw-bold">Order :</p>
                    <ul class="mb-3">
                        <li v-for="(dt, index) in reci" :key="index">
                            <b>{{ dt.description }}</b> Qty: {{ dt.Qty }} x {{ dt.unitPrice | toRp }} = {{ dt.netAmt | toRp }}
                        </li>
                    </ul>
                    <p class="h5 fw-bold">total order : {{ totalOrder | toRp }}</p>
                    <p class="h5 fw-bold">Outstanding : {{ totalNetAmt | toRp }}</p>
                    <ul class="mb-3">
                        <li v-for="(dt, index) in reco" :key="index">
                            <b>{{ dt.trdate }}</b> : {{ dt.netamt1 | toRp }}
                        </li>
                    </ul>
                    <div v-for="(dt, index) in rect" :key="index">
                        <p>LTOP : {{dt.top_days}} Hari</p>
                        <p>RTOP : {{dt.diff}} Hari</p>
                    </div>
                </div>

            </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar2.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";


export default {

    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Approved',
    components: {
        Toolbar
    },
    data() {
        return {
            username : localStorage.username,
            userz : [],
            rec: {
            ta_so: '',
            ta_enty: '  ',},
            reci: [],
            reco : [],
            recc : [],
            rect : [],
            top : [],
            showDiv: false,

        }
    },
    methods : {
        saveData(event){
            var self = this;
            self.rec = {
                pfunction : 'show',
                so : self.rec.ta_so,
                ent : self.rec.ta_enty,
            }
            axios.post("marketing/ApiPengajuan.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.reci = response.data.rec;
                    self.reco = response.data.recO;
                    self.recc = response.data.recc;
                    self.top = response.data.top_id;
                    self.rect = response.data.rect;
                    self.showDiv = true;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        copyToClipboard() {
        const contentToCopy = this.generateCopyContent();

        // Create a temporary textarea element to hold the content
        const textarea = document.createElement('textarea');
        textarea.value = contentToCopy;
        document.body.appendChild(textarea);

        // Select the text in the textarea
        textarea.select();
        document.execCommand('copy');

        // Remove the temporary textarea
        document.body.removeChild(textarea);

        // You can also provide feedback to the user, e.g., show a message or change the button text
        alert('Copied to clipboard!');
        },

        generateCopyContent() {
        // Customize this method to return the content you want to copy
        const customerInfo = `Customer: *${this.recc[0].custname}*\n`;
        const creditLimit = `Credit Limit: *${this.$options.filters.toRps(this.recc[0].creditlimit)}*\n`;
        const sisaCredit = `Sisa Credit: *${this.$options.filters.toRps(this.recc[0].creditlimit - this.totalNetAmt)}*\n`;
        const Tops = `Top: *${this.top}*\n\n`;

        const orderInfo = this.reci.map(dt => `*${dt.description}* Qty: ${dt.Qty} x ${this.$options.filters.toRps(dt.unitPrice)} = *${this.$options.filters.toRps(dt.netAmt)}*`).join('\n');

        const totalOrder = `\n\nTotal Order: *${this.$options.filters.toRps(this.calculateTotalOrder())}*\n`;
        const totalOutstanding = `\nTotal Outstanding: *${this.$options.filters.toRps(this.totalNetAmt)}*\n\n`;

        const outstanding = this.reco.map(dt => `${dt.trdate} : ${this.$options.filters.toRps(dt.netamt1)}`).join('\n');

        const ltopInfo = this.rect.map(dt => `\n\nLTOP: *${dt.top_days} Hari*\nRTOP: *${dt.diff} Hari*`).join('\n');

        // Combine all the information
        return `${customerInfo}${creditLimit}${sisaCredit}${Tops}${orderInfo}${totalOrder}${totalOutstanding}${outstanding}${ltopInfo}`;
        },
        calculateTotalOrder() {
            // Implement the logic to calculate the total order based on your requirements
            // For example, you might iterate over this.reci and sum up the netAmt values
            // Replace the following line with your actual logic
            return this.reci.reduce((total, dt) => total + dt.netAmt, 0);
        }

    },
    filters: {
        toRp(value) {
        return `${Intl.NumberFormat("id-ID", { style: 'currency', currency: 'IDR' }).format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        },
        toRps(value) {
            return Intl.NumberFormat("id-ID", { style: 'currency', currency: 'IDR' }).format(value);
        }
    },
    watch: {
        'rec.ta_so': function(newVal) {
        if (newVal.startsWith('1SOP')) {
            this.rec.ta_enty = '01';
        } else if (newVal.startsWith('03SO')) {
            this.rec.ta_enty = '02';
        } else {
            // Set a default value or handle other cases
            this.rec.ta_enty = '01';
        }
        },
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        },
        isFormInvalid() {
            return !this.rec.ta_so || !this.rec.ta_enty;
        },
        totalNetAmt() {
            return this.reco.reduce((total, dt) => total + dt.netamt1, 0);
        },
        totalOrder() {
            return this.reci.reduce((total, dt) => total + dt.netAmt, 0);
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;

    }
};
</script>